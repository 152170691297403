<template>
  <div class="min-h-screen bg-gradient-to-b from-black to-gray-900 text-white">
    <!-- Hero Section -->
    <div class="relative h-[60vh]">
      <div class="absolute inset-0">
        <img 
          src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/2023_140%20(1).jpg-gOlS6yo7ZajZ94jXgyhAdIMtlQvrKu.jpeg" 
          alt="Le Vieux Port de La Rochelle dans la brume matinale" 
          class="w-full h-full object-cover"
        />
        <div class="absolute inset-0 bg-black opacity-40"></div>
      </div>
      <div class="relative z-10 flex flex-col items-center justify-center h-full text-center px-4">
        <h1 class="text-5xl sm:text-6xl font-bold text-white mb-4 font-playfair">
          Sawadogo Yao Eric
        </h1>
        <p class="text-xl text-gray-200 max-w-2xl mx-auto font-lato">
          Photographe & Créateur de "Vivre La Rochelle"
        </p>
      </div>
    </div>

    <!-- Contenu Principal -->
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <!-- Section Introduction -->
      <div class="prose prose-lg prose-invert mx-auto mb-16">
        <div class="bg-gray-800/50 p-8 rounded-2xl backdrop-blur-sm">
          <h2 class="text-3xl font-playfair text-yellow-400 mb-6">Mon Histoire</h2>
          <p class="text-gray-300 leading-relaxed mb-6">
            Originaire du Burkina Faso, ma passion pour la photographie et la culture a commencé à Bobo-Dioulasso, 
            où j'ai collaboré avec des artistes renommés comme Floby et Imilo Lechanceux à travers ma plateforme "Faso Show". 
            Il y a deux ans, ma vie a pris un nouveau tournant lorsque je suis arrivé à La Rochelle, 
            une ville qui m'a instantanément captivé par son charme unique.
          </p>
        </div>
      </div>

      <!-- Section Vision -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-12 mb-16">
        <div class="bg-gray-800/50 p-8 rounded-2xl backdrop-blur-sm">
          <Camera class="w-12 h-12 text-yellow-400 mb-4" />
          <h3 class="text-2xl font-playfair text-yellow-400 mb-4">Ma Vision</h3>
          <p class="text-gray-300 leading-relaxed">
            "Vivre La Rochelle" est né de mon désir de révéler les trésors cachés de cette ville magnifique. 
            À travers mon regard unique, influencé par mes racines burkinabè et mon amour pour La Rochelle, 
            je capture l'essence de cette ville où histoire et modernité se rencontrent.
          </p>
        </div>
        <div class="bg-gray-800/50 p-8 rounded-2xl backdrop-blur-sm">
          <Heart class="w-12 h-12 text-yellow-400 mb-4" />
          <h3 class="text-2xl font-playfair text-yellow-400 mb-4">Ma Mission</h3>
          <p class="text-gray-300 leading-relaxed">
            Mon objectif est de construire des ponts entre les cultures, de raconter des histoires visuelles qui touchent les cœurs, 
            et d'inspirer chacun à découvrir ou redécouvrir La Rochelle sous un angle nouveau et authentique.
          </p>
        </div>
      </div>

      <!-- Section Réalisations -->
      <div class="bg-gray-800/50 p-8 rounded-2xl backdrop-blur-sm mb-16">
        <h2 class="text-3xl font-playfair text-yellow-400 mb-8 text-center">Projets Marquants</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div v-for="(project, index) in projects" :key="index" class="p-6 bg-gray-700/50 rounded-xl">
            <component :is="project.icon" class="w-8 h-8 text-yellow-400 mb-4" />
            <h3 class="text-xl font-playfair text-yellow-400 mb-2">{{ project.title }}</h3>
            <p class="text-gray-300">{{ project.description }}</p>
          </div>
        </div>
      </div>

      <!-- Section Contact -->
      <div class="text-center bg-gray-800/50 p-8 rounded-2xl backdrop-blur-sm">
        <h2 class="text-3xl font-playfair text-yellow-400 mb-6">Rejoignez l'Aventure</h2>
        <p class="text-gray-300 mb-8 max-w-2xl mx-auto">
          Partagez vos idées, vos lieux favoris, et vos souvenirs. 
          Ensemble, faisons vivre La Rochelle et célébrons sa beauté unique.
        </p>
        <div class="flex justify-center space-x-4">
          <button class="bg-yellow-400 text-black px-6 py-3 rounded-full hover:bg-yellow-500 transition duration-300 flex items-center">
            <Mail class="w-5 h-5 mr-2" />
            Me Contacter
          </button>
          <button class="bg-gray-700 text-white px-6 py-3 rounded-full hover:bg-gray-600 transition duration-300 flex items-center">
            <Instagram class="w-5 h-5 mr-2" />
            Instagram
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Camera, Heart, ImageIcon, Users, Calendar, Globe, Mail, Instagram } from 'lucide-vue-next'

const projects = [
  {
    icon: ImageIcon,
    title: "Expositions Photos",
    description: "Mise en lumière du Vieux Port, des tours médiévales et des moments de vie rochelaise à travers des expositions captivantes."
  },
  {
    icon: Users,
    title: "Collaborations Artistiques",
    description: "Projets avec des artistes locaux et internationaux, créant des ponts entre les cultures burkinabè et rochelaise."
  },
  {
    icon: Calendar,
    title: "Événements Culturels",
    description: "Couverture des festivals, marchés et rencontres culturelles pour connecter les Rochelais à leur patrimoine."
  },
  {
    icon: Globe,
    title: "Contenu Digital",
    description: "Animation d'une communauté grandissante sur les réseaux sociaux, partageant la passion pour La Rochelle."
  }
]
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Lato:wght@300;400;700&display=swap');

.font-playfair {
  font-family: 'Playfair Display', serif;
}

.font-lato {
  font-family: 'Lato', sans-serif;
}

.prose {
  max-width: 65ch;
}
</style>